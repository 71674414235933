import { template as template_6b2d2f4f2f5146cfbca4ba36e674f9e0 } from "@ember/template-compiler";
const FKLabel = template_6b2d2f4f2f5146cfbca4ba36e674f9e0(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
