import { template as template_a4cd9484412f4c54a7a46c305b5c8fc6 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_a4cd9484412f4c54a7a46c305b5c8fc6(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
