import { template as template_5d3960f18be9487896a13480d9434aff } from "@ember/template-compiler";
const WelcomeHeader = template_5d3960f18be9487896a13480d9434aff(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
